<template>
	<div>
		<div class="row">
			<div class="col-3 form-group">
        		<label class="col-form-label">{{ $t('monte.stallion') }}</label>
                <e-select
                    v-model="stallions_selected"
                    id="stallion"
                    track-by="horse_id"
                    label="horse_nom"
                    :placeholder="$t('monte.selectionnez_etalon')"
                    :selectedLabel="$t('global.selected_label')"
                    :options="all_stallions"
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    :multiple="true"
					:loading="loadingHorse"
                />
            </div>

            <div class="col-3 form-group">
        		<label for="tiers" class="col-form-label">{{ $t("monte.stock_semence.provenance") }}</label>
				<e-select
					v-model="tiers"
				    id="tiers"
				    track-by="tiers_rs"
				    label="tiers_rs"
				    :placeholder="$t('tiers.search_tiers')"
				    :selectedLabel="$t('global.selected_label')"
				    :options="all_tiers"
				    :searchable="true"
				    :show-labels="false"
					:loading="loadingTiers"
				/>
            </div>

            <div class="col-3 form-group">
        		<label for="entity" class="col-form-label">{{ $t("monte.stock_semence.tiers_vers") }}</label>
				<e-select
					v-model="entity"
				    id="entity"
				    track-by="tiers_rs"
				    label="tiers_rs"
				    :placeholder="$t('tiers.search_tiers')"
				    :selectedLabel="$t('global.selected_label')"
				    :options="all_entity"
				    :searchable="true"
				    :show-labels="false"
					:loading="loadingTiers"
				/>
            </div>

            <div class="col-3 form-group">
        		<label class="col-form-label">{{ $t("monte.stock_semence.date_import") }}</label>
				<e-datepicker v-model="date_import"></e-datepicker>
            </div>
		</div>

		<div v-if="reloadStock">
			<div v-for="(stallion, id) in stocks" :key="id" class="mb-2">
				<div class="d-flex">
					<h3>{{ stallion.name }}</h3>
					<button class="btn btn-secondary ml-3" @click="addStockStallion(id)"><font-awesome-icon :icon="['far', 'plus']" /></button>

					<button v-if="stallion.stock[0].stock.length > 0" class="btn btn-secondary ml-3" :id="'tooltip-target-'+id"><font-awesome-icon :icon="['far', 'map-marker-alt']" /></button>
					<b-tooltip placement="right" :target="'tooltip-target-'+id" triggers="hover" style="min-width:500px; !important">
						<span v-for="(stock, idx) in stallion.stock[0].stock" :key="idx">
							{{ stock }}<br>
						</span>
					</b-tooltip>
				</div>
				
				<div v-for="(stock, index) in stallion.stock" :key="index" class="row border-bottom pb-2" >
					<div class="col-4">
		                <div class="form-group">
		                    <label for="lot" class="col-form-label">{{ $t("monte.stock_semence.numero_lot") }}</label>
		                    <input type="text" id="lot" class="form-control" v-model="stock.lot" required>
		                </div>
		            </div>

					<div class="col-4">
		                <div class="form-group">
		                    <label for="qte" class="col-form-label">{{ $t("monte.stock_semence.qte") }}</label>
		                    <input type="number" id="qte" class="form-control" v-model="stock.qte" required>
		                </div>
		            </div>

		            <div class="col-4">
		                <div class="form-group">
		                    <label for="couleur" class="col-form-label">{{ $t("monte.stock_semence.couleur") }}</label>
		                    <e-select
			                    v-model="stock.couleur"
			                    id="couleur"
			                    track-by="semencecouleur_code"
			                    :placeholder="$t('monte.stock_semence.selectionnez_couleur')"
			                    :selectedLabel="$t('global.selected_label')"
			                    :options="all_couleurs"
			                    :searchable="true"
			                    :allow-empty="false"
			                    :show-labels="false"
								@input="resfreshStock"
								>
									<template slot="option" slot-scope="{ option }">{{ $t('monte.couleur.'+option.semencecouleur_code) }}</template>
									<template slot="singleLabel" slot-scope="{ option }">{{ $t('monte.couleur.'+option.semencecouleur_code) }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
		                </div>
		            </div>

					<div class="col-6">
		                <div class="form-group">
		                   <label for="localisation" class="col-form-label">{{ $t("monte.stock_semence.localisation") }}</label>
		                   <e-select
								v-model="stock.localisation"
								id="localisation"
								track-by="full_name"
								label="full_name"
								:placeholder="$t('monte.stock_semence.rechercher_categorie')"
								:selectedLabel="$t('global.selected_label')"
								:options="all_localisations"
								:searchable="true"
								:show-labels="false"
								:allow-empty="true"
								:sortable="false"
								:optionsLimit="all_localisations.length"
								@input="resfreshStock"
							>
								<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
							</e-select>
		                </div>
		            </div>

		            <div class="col-6">
		            	<div class="from-group">
		            		<label for="commentaire" class="col-form-label">{{ $t("monte.stock_semence.commentaire") }}</label>
							<textarea id="commentaire" class="form-control" :placeholder="$t('formulaire.votre_commentaire')" v-model="stock.commentaire"></textarea>
		            	</div>
		            </div>
				</div>

			</div>
		</div>
			
		<div>
			<div class="col-12 text-center mt-3">
	        	<button @click="submitForm" class="btn btn-primary rounded-pill">
	        		<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
	                <font-awesome-icon v-else :icon="['far', 'plus']" /> {{ $t('global.ajouter') }}
	            </button>
	        </div>
	    </div>
	</div>
</template>

<script type="text/javascript">
import Shutter from '@/mixins/Shutter'
import ShutterSemence from '@/mixins/shutters-manager/Semence'
import StockSemence from '@/mixins/StockSemence'
import Tiers from '@/mixins/Tiers'
import Horse from '@/mixins/Horse'

	export default {
		name: 'ImportStock',
		mixins: [Shutter, ShutterSemence, StockSemence, Tiers, Horse],
		data () {
			return {
				all_couleurs: [],
				all_localisations: [],
				all_tiers: [],
				all_entity: [],
				all_stallions: [],
				loadingHorse: false,
				loadingTiers: false,
				stallions_selected: [],
				tiers: null,
				entity: null,
				date_import: new Date(),
				stocks: {},
				reloadStock: true,
				processing: false,
				all_stocks: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loadingHorse = true
				this.all_stallions = await this.getHorsesStallion(false)
				this.loadingHorse = false

				this.loadingTiers = true
				this.all_tiers = await this.loadTiers()
				this.all_entity = this.all_tiers.filter(tier => tier.tiers_entity == 1)
				this.loadingTiers = false
				this.entity = this.all_entity[0]
				this.tiers = this.all_tiers[0]

				this.all_couleurs = await this.getAllSemenceCouleur()
				const all_localisations = await this.getLocalisationSemenceFullName()
				this.all_localisations = all_localisations.filter(loc => !loc.semencelocalisation_transport)
				this.all_stocks = await this.getStockStallionByLocalisation()
			},

			addStockStallion(id) {
				this.reloadStock = false

				let stocks = this.all_stocks.filter(stock => stock.horse == id)
				stocks = stocks.map(stock => stock.localisation + " (" + stock.qte + ")")

				this.stocks[id].stock.push({
					lot: '',
					qte: 0,
					couleur: null,
					localisation: null,
					commentaire: '',
					stock: stocks
				})
				this.reloadStock = true
			},

			resfreshStock() {
				this.reloadStock = false
				this.reloadStock = true
			},

			async submitForm() {
				this.processing = true

				let stock_to_send = []
				for(let stallion in this.stocks) {
					this.stocks[stallion].stock.forEach(stock => {
						if(stock.qte > 0) {
							stock_to_send.push({
								lot: stock.lot,
								stock: stock.qte,
								couleur: stock.couleur.semencecouleur_code,
								date: this.date_import,
								commentaire: stock.commentaire,
								stallion: stallion,
								from: this.tiers ? this.tiers.tiers_id : null,
								to: this.entity ? this.entity.tiers_id : null,
								localisation: stock.localisation ? stock.localisation.semencelocalisation_id : null,
							})
						}
					})
				}

				await this.addStockSemence(stock_to_send)

				this.processing = false

				this.ok()
				this.shutterPanel().close('import-stock')
			}
		},

		watch: {
			stallions_selected(newV, oldV) {
				let new_stallion = newV.filter(x => !oldV.includes(x))[0]
	
				if(!new_stallion) {
					let delete_stallion = oldV.filter(x => !newV.includes(x))[0]
					delete this.stocks[delete_stallion.horse_id]
				}
				else {
					let stocks = this.all_stocks.filter(stock => stock.horse == new_stallion.horse_id)
					stocks = stocks.map(stock => stock.localisation + " (" + stock.qte + ")")

					this.stocks[new_stallion.horse_id] = {
						name: new_stallion.horse_nom,
						stock: [{
							lot: '',
							qte: 0,
							couleur: null,
							localisation: null,
							commentaire: '',
							stock: stocks
						}]
					}
				}
			}
		}
	}

</script>